import React, { useState, useEffect } from "react";
import queryString from "querystring";
import { ExternalLink, ExternalLinkTypes } from "../components/links";
import { ContainerContentFixed, Layout, SEO } from "../components";
import { Colors } from "../components/constants/colors";

const Referral = ({ location }) => {
  const [source, setSource] = useState();
  console.log("LOCATION", location);
  console.log("source", source);

  useEffect(
    () => {
      if (location && location.search) {
        const params = queryString.parse(location.search);
        console.log("params", params);

        if (params.website) setSource(params.website);
        if (params["?website"]) setSource(params["?website"]);
      }
    },
    [location]
  );

  return (
    <Layout>
      <SEO title={"Referral"} />
      <ContainerContentFixed>
        <div style={{ textAlign: "center", maxWidth: "600px", margin: "auto" }}>
          <h1 style={{ color: Colors.Teal, fontWeight: 200 }}>
            Looks like you like our work at{" "}
            {source ? source : "one of the websites we made"}
          </h1>
          <h3 style={{ fontWeight: 400 }}>
            Unfortunately, our website is still under construction. But we are
            ready to build one for you, just like the one you came from.
          </h3>
          <p>
            Reach us at{" "}
            <ExternalLink url={ExternalLinkTypes.GeneralPhoneLink}>
              {ExternalLinkTypes.GeneralPhoneText}
            </ExternalLink>{" "}
            or{" "}
            <ExternalLink url={ExternalLinkTypes.GeneralEmailLink}>
              {ExternalLinkTypes.GeneralEmailText}
            </ExternalLink>
            or chat with us.
          </p>
        </div>
      </ContainerContentFixed>
    </Layout>
  );
};

export default Referral;
